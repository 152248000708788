import { createRouter, createWebHistory } from 'vue-router'

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/components/HelloWorld.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/components/MainHome.vue')
      },
      {
        path: '/zhangxinyue/upload_text',
        name: 'text_blog',
        component: () => import('@/components/UploadText.vue')
      },
    // 其他路由配置
  ]
})

// 导出路由实例
export default router
