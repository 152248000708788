import { createApp } from 'vue'
import router from './router/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import '@/assets/css/font.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import Particles from "particles.vue3";
import VueParticles from 'vue-particles'

const app = createApp(App)
app.use(router).use(ElementPlus).use(VueParticles)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.mount('#app')