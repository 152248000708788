<template>
   <router-view />

</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
* {
  cursor: url('@/assets/Location.cur'), pointer; /* 修改全局鼠标样式为手型 */
}

</style>
